/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"

import { ContentSingle } from "page_components/office"

const Apartment = ({ pageContext, location }) => {
  const isBrowser = typeof window !== "undefined"
  const data = pageContext?.data
  const acfOffice = pageContext?.data?.data?.acfOffice

  useEffect(() => {
    isBrowser &&
      window?.ylData?.push({
        product: { products: [`${data?.data?.id}`] },
      })
  }, [])

  return (
    <Layout location={location} seo={{ title: data?.title }} rmGlobalPopup>
      <Breadcrumbs
        parent={{ label: "Biura i lokale", url: "/biura-i-lokale" }}
        title={data?.title}
      />
      <ContentSingle
        data={data}
        acfOffice={acfOffice}
        investmentRaw={data?.investmentRaw}
      />
      <ContactForm
        formType="sale"
        investment={data?.investment}
        investmentRaw={data?.investmentRaw}
      />
    </Layout>
  )
}

export default Apartment
